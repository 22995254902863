import { Component, OnInit } from "@angular/core";
import { control, Subcontrol } from "pck-general-components";
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ThemeOptions } from '../theme-options';
import { JsonpClientBackend } from '@angular/common/http';

@Component({
  selector: "gc-accordion",
  templateUrl: "./accordion.component.html",
  styleUrls: ["./accordion.component.css"],
})
export class AccordionComponent implements OnInit {
  @select('config') public config$: Observable<any>;
  public submenu: Array<control> = [
    {
      icon: "",
      title: "SubMenu.Masters",
      id: "1",
      parentid: "2",
      url: "",
      aclmodule: ["Dashboard"],
      Subcontrol: [
        {
          icon: "",
          title: "SubMenu.Modules",
          id: "1.1",
          parentid: "2",
          url: "/license/modules",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.Product",
          id: "1.2",
          parentid: "2",
          url: "/license/product",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.ClientRegistration",
          id: "1.3",
          parentid: "2",
          url: "/license/AccountRegistration",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.Subscription",
          id: "1.4",
          parentid: "2",
          url: "/license/Subscription",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.Employee",
          id: "1.5",
          parentid: "2",
          url: "/license/employee",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.Currency",
          id: "1.6",
          parentid: "2",
          url: "/license/currency",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.CurrencyConversion",
          id: "1.7",
          parentid: "2",
          url: "/license/currencyconversion/create",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.country",
          id: "1.8",
          parentid: "2",
          url: "/license/country",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.payment",
          id: "1.9",
          parentid: "2",
          url: "/license/payment",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.User",
          id: "1.10",
          parentid: "2",
          url: "/license/users",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.Notification",
          id: "1.11",
          parentid: "2",
          url: "/license/notification",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.templatesetting",
          id: "1.12",
          parentid: "2",
          url: "/license/template-setting",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.providersetting",
          id: "1.13",
          parentid: "2",
          url: "/license/provider-setting",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.notificationconfiguration",
          id: "1.14",
          parentid: "2",
          url: "/license/notification-configuration",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.clientProductList",
          id: "1.14",
          parentid: "2",
          url: "/license/clientproduct",
          aclmodule: ["Dashboard"],
        },
        {
          icon: "",
          title: "SubMenu.CreditMaster",
          id: "1.15",
          parentid: "2",
          url: "/license/creditmaster",
          aclmodule: ["Dashboard"],
        }
      ],
    }
  ];

  ParentMenu: control[];
  submenufilter: Array<control>;
  permissionMenu: Array<control> = [];
  permissionSubcontrol: Array<Subcontrol> = [];
  static ParentId: string;
  constructor(public globals: ThemeOptions) {
    this.submenufilter = this.submenu.filter(
      (t) => t.parentid == AccordionComponent.ParentId
    );

    var permission = JSON.parse(this.globals.aclModule);
    this.submenufilter.forEach(menu => {
      permission.forEach(element => {
        if (menu.aclmodule.length > 0 && menu.aclmodule.includes(element.Name) && (element.permission > 0 && (menu.Subcontrol === undefined || menu.Subcontrol.length === 0))) {
          this.permissionMenu.push(menu);
        }
      });
      if (menu.aclmodule.length > 0 && menu.Subcontrol != undefined && menu.Subcontrol.length > 0) {
        this.permissionSubcontrol = [];
        permission.forEach(element => {
          menu.Subcontrol.forEach(submenu => {
            if (submenu.aclmodule.includes(element.Name) && element.permission > 0) {
              this.permissionSubcontrol.push(submenu);
            }
          });
        });
        if (this.permissionSubcontrol.length > 0) {
          menu.Subcontrol = this.permissionSubcontrol;
          this.permissionMenu.push(menu);
        }
      }
    });
  }
  // @select('config') public config$: Observable<any>;

  ngOnInit() { }
}
