<div class="h-100">
  <div class="row h-100 no-gutters">
    <div class="d-none d-lg-block col-lg-4">
      <div class="slider-light">
        <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig2">
          <div ngxSlickItem *ngFor="let data of staticData">
            <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-plum-plate">
              <div class="slide-img-bg"></div>
              <div class="slider-content text-light">
                <h3> {{ data.title }}</h3>
                <p>
                  {{ data.content }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div ngxSlickItem>
                        <div
                            class="position-relative h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                            <div class="slide-img-bg"></div>
                            <div class="slider-content text-light">
                                <h3>Scalable, Modular, Consistent</h3>
                                <p>
                                    Easily exclude the components you don't require. Lightweight,
                                    consistent Bootstrap based styles across all elements and
                                    components
                                </p>
                            </div>
                        </div>
                    </div>
                    <div ngxSlickItem>
                        <div
                            class="position-relative h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                            <div class="slide-img-bg opacity-6"></div>
                            <div class="slider-content text-light">
                                <h3>Complex, but lightweight</h3>
                                <p>
                                    We've included a lot of components that cover almost all use
                                    cases for any type of application.
                                </p>
                            </div>
                        </div>
                    </div> -->
        </ngx-slick-carousel>
      </div>
    </div>
    <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
      <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
        <div class="app-logo"></div>
        <!-- <h6 class="mt-3">
          No account?
          <a routerLink="/Registration" class="text-primary">Sign up now</a>
        </h6> -->
        <!-- <h4 class="mb-0">
          <div>Welcome </div>
                    <span>Please sign in to your account.</span>
        </h4>
        <h6 class="mt-3">
          No account?
                    <a [routerLink]="" class="text-primary">Sign up now</a>
        </h6> -->
        <!-- <div class="divider"></div> -->
        <div>
          <div *ngIf="ErrorDescription" class="text-danger">
            <label>{{ErrorDescription}}</label>
          </div>
          <div class="divider"></div>
          <form [formGroup]="formGroup" class="needs-validation" novalidate>
            <div class="row">
              <div class="col-md-6">
                <fieldset class="form-group">
                  <div tabindex="-1" role="group">
                    <label for="email">Email</label>
                    <input autofocus formControlName="username" class="form-control mr-sm-2" type="email"
                      placeholder="xyz@abc.com" [(ngModel)]="model.username"
                      [ngClass]="{'is-invalid': submitted && loginform.username.errors}"
                      (change)="model.username=model.username.trim()" />
                    <div *ngIf="submitted && loginform.username.errors" class="invalid-feedback">
                      <div *ngIf="loginform.username.errors.required">
                        <label translate>Common.Required</label>
                      </div>
                      <div *ngIf="loginform.username.errors.email">
                        <label translate>Common.EmailFormat</label>
                      </div>
                    </div>
                    <div *ngIf="formGroup.get('username').errors?.serverError" class="invalid-feedback">
                      <label>{{translationService.translate(formGroup.get("username").errors?.serverError)}}</label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <fieldset class="form-group">
                  <div tabindex="-1" role="group">
                    <label for="examplePassword">Password</label>
                    <input class="form-control mr-sm-2" formControlName="password" type="password" name="password"
                      [ngClass]="{'is-invalid': submitted && loginform.password.errors}" placeholder="Password"
                      [(ngModel)]="model.password" />
                    <div *ngIf="submitted && loginform.password.errors" class="invalid-feedback">
                      <div *ngIf="loginform.password.errors.required">
                        <label translate>Common.Required</label>
                      </div>
                    </div>
                    <div *ngIf="formGroup.get('password').errors?.serverError" class="invalid-feedback">
                      <label>{{translationService.translate(formGroup.get("password").errors?.serverError)}}</label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="custom-control custom-checkbox">
              <!-- <input id="exampleCheck" type="checkbox" name="check" autocomplete="off"
                                class="custom-control-input" value="true" /><label for="exampleCheck"
                                class="custom-control-label">
                                Keep me logged in
                            </label> -->
            </div>
            <div class="divider"></div>
            <div class="d-flex align-items-start">
              <div class="col-md-6">
                <button type="submit" class="btn btn-primary btn-lg" (click)="login()">Login</button>
                <!-- <a [routerLink]="" class="btn-lg btn btn-link">Forgot Password?</a> -->
              </div>
              <div class="col-6 text-right">
                <!-- <button (click)="loginWithGoogle('google')" class="loginBtn loginBtn--google">
                                    Login with Google
                                </button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>