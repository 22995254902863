import { Component } from "@angular/core";
import { UserIdleService } from 'angular-user-idle';
import { LoginauthService } from './Service/Loginauth.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  constructor(private authService: LoginauthService,
    private userIdle: UserIdleService) {
  }
  ngOnInit() {
    var eventList = ['keydown', 'DOMMouseScroll',
      'mousedown', 'scroll', 'keyup'];

    for (let event of eventList) {
      document.body.removeEventListener(event, () => null);
    }
    if (localStorage.getItem("RefreshToken") != null && localStorage.getItem("RefreshToken") != "") {
      this.authService.StartUserActivity();
    }
    else {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
    }
  }
}
