import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { LoginauthService } from '../Service/Loginauth.service';
import { Router } from '@angular/router';
import { LoginResponse } from '../Modal/User';
import { LoaderService } from '../Service/loader.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor
{
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public authService: LoginauthService, public router: Router, private loaderService: LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // if (this.authService.setUser)
        // {
        //     request = this.addToken(request, this.authService.getJwtToken());
        // }
        // return next.handle(request)
        //     .pipe(
        //         catchError(this.handleError)
        //     );
        return next.handle(request).pipe(catchError(error =>
        {
            if (error instanceof HttpErrorResponse && error.status != undefined && error.status === 401)
            {
                return this.handle401Error(request, next);
            } else
            {
                return throwError(error);
            }
        }));
        // return next.handle(request).pipe(catchError(error => {
        //     if (error instanceof HttpErrorResponse && error.status === 401) {
        //         return this.handle401Error(request, next);
        //     } else {
        //         return throwError(error);
        //     }
        // }));
    }
    handleError(error: HttpErrorResponse)
    {
        if (error instanceof HttpErrorResponse && error.status === 401)
        {

            // A client-side or network error occurred. Handle it accordingly.

            console.error('An error occurred:', error.error.message);
        } else
        {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }
    private addToken(request: HttpRequest<any>, token: string)
    {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }
    private handle401Error(request: HttpRequest<any>, next: HttpHandler)
    {
        if (localStorage.getItem('RefreshToken') == null ||
            localStorage.getItem('RefreshToken') == '')
        {
            localStorage.clear();
            this.router.navigate(['/login']);
            this.loaderService.isLoading.next(false);
        }

        if (!this.isRefreshing)
        {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(switchMap((token: LoginResponse) =>
            {
                this.isRefreshing = false;
                if (token.status.toLowerCase() == "fail")
                {
                    localStorage.clear();
                    this.router.navigate(['/login']);
                    this.loaderService.isLoading.next(false);
                }
                else
                {
                    this.refreshTokenSubject.next(token.Accesstoken);

                    return next.handle(this.addToken(request, token.Accesstoken));
                }
            }));

        } else
        {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt =>
                {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }

}
