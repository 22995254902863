import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, throwError as observableThrowError } from 'rxjs';
import { LoginResponse } from '../Modal/User';
import { retry, catchError, tap } from 'rxjs/operators';
import { Socialusers } from '../Modal/Socialusers';
import { EnvironmentService, RejectedResponse } from 'pck-api';
import { UserIdleService } from 'angular-user-idle';
import Swal from 'sweetalert2';
import { BusinessAccount } from '../Modal/BusinessAccount';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jwt_decode from "jwt-decode";
import { CommonMethod, ResourceTranslationService } from 'pck-general-components';
import { SharedService } from './shared.service';
import { LogoutModal } from '../Modal/logout-model';

//import { GoogleLoginProvider, AuthService as Auth } from 'angular-6-social-login';

@Injectable({
  providedIn: 'root'
})

export class LoginauthService {
  private headerCollection: HttpHeaders;
  basePath: string;
  logoutBasepath: string;
  logoutSessionpath: string;
  businessAccountUrl: string;
  eventName: string;
  private readonly JWT_TOKEN = 'access_token';
  private readonly REFRESH_TOKEN = 'RefreshToken';
  response;
  eventList = ['keydown', 'DOMMouseScroll', 'mousedown', 'scroll', 'keyup'];
  socialusers = new Socialusers();
  clienttId: string = "";
  clienttSecret: string = "";
  grantTypeRefreshToken: string;
  errorDescription: string;
  tokenEmail: string;
  userName: string;
  firstChar: string;
  userImagePath: string;
  logoutModel = new LogoutModal();
  ipAddress: any;
  swalOpen: string = "close";

  constructor(private router: Router, private http: HttpClient
    //, public OAuth: Auth,
    , environmentData: EnvironmentService,
    private userIdle: UserIdleService,
    private modalService: NgbModal,
    public loginenvironmentData: EnvironmentService,
    public commonMethod: CommonMethod,
    public translationService: ResourceTranslationService,
    public sharedService: SharedService,
  ) {
    console.log(environmentData.UserServiceUrl);
    this.basePath = environmentData.UserServiceUrl + "/token";
    this.logoutBasepath = environmentData.UserServiceUrl + "/token/logout";
    this.logoutSessionpath = environmentData.UserServiceUrl + "/token/sessionlogout";
    this.clienttId = environmentData.ClientId;
    this.clienttSecret = environmentData.ClientSecret;
    this.grantTypeRefreshToken = environmentData.GrantTypeRefreshToken;
    this.businessAccountUrl = environmentData.businessAccountserviceUrl + "/BusinessAccount/postsubscribeuserdetail";
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': ['application/json', 'multipart/form-data'],
      "client_id": this.clienttId,
      "client_secret": this.clienttSecret,
    })
  };
  handleError(error: HttpErrorResponse) {

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
  loginForm(data): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(this.basePath, data)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  logoutForm(data): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(this.logoutBasepath, data)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }
  create(entity: BusinessAccount) {

    var postReq = this.http.post(this.businessAccountUrl, entity);
    return this.http
      .post<BusinessAccount>(this.businessAccountUrl, entity,)
      .pipe(
        tap((res) => {
          console.log("-> Result", res);
        }),
        catchError((error: any) => {
          return observableThrowError(new RejectedResponse(error));
        })
      )
      .toPromise();
  }
  // create(entity: subscriptiondetail) {

  //   var postReq = this.http.post(this.businessAccountUrl, entity);
  //   return this.http
  //     .post<any>(this.businessAccountUrl, entity)
  //     .pipe(
  //       tap((res) => {
  //         console.log("-> Result", res);
  //       }),
  //       catchError((error: any) => {
  //         return observableThrowError(new RejectedResponse(error));
  //       })
  //     )
  //     .toPromise();
  // }

  // // updatesubscription(subscriptiondetail: string): Promise<void> {
  // //   let url = this.environmentData.licenseServiceUrl + `/subscription/updatesubscription`;
  // //   return this.post(url, subscriptiondetail);
  // // }

  CreateBussinessAccount(data: BusinessAccount): Observable<BusinessAccount> {
    return this.http
      .post<BusinessAccount>(this.businessAccountUrl, data)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  // After login save token and other values(if any) in localStorage
  StartUserActivity() {

    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(count => {

      //console.log("count:" + count);
      if (this.swalOpen.toLowerCase() == "close" && count > 0) {
        this.swalWarning();
        this.userIdle.stopWatching();
      }
      // if (!Swal.isVisible() && count > 0)

      // else
      //   this.userIdle.stopWatching();


      for (let event of this.eventList) {
        document.body.addEventListener(event, () => this.userIdle.resetTimer());
      }
      //console.log(count);

    });
    this.userIdle.ping$.subscribe(() => { this.refreshToken(); });

    //// this.userIdle.onTimeout().subscribe(() => {
    ////   this.eventName = "On time Logout";
    ////   console.log("Logoutcall")
    ////   this.userIdle.stopWatching();
    ////   //this.sessionLogout(this.tokenEmail);
    ////   if (Swal.isVisible()) {

    ////     // this.logout(true);
    ////     localStorage.clear();
    ////     this.modalService.dismissAll();
    ////   } else {
    ////     this.swalWarning();
    ////   }
    ////   // Swal.close();
    //// });

  }
  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }
  swalWarning() {
    var token, decoded;

    this.swalOpen = "open";
    if (this.tokenEmail == undefined) {
      token = localStorage.getItem("access_token");
      decoded = jwt_decode(token);
      this.userName = this.commonMethod.getTokenValue("idp");//decoded.idp;
      this.tokenEmail = this.commonMethod.getTokenValue("email");
      this.firstChar = this.userName.substr(0, 1);
      this.userImagePath = "./assets/images/avatars/" + this.firstChar.toLowerCase() + ".png";
      //this.logoutCall();
      // this.sessionLogout(this.tokenEmail);

      // localStorage.clear();
      localStorage.removeItem('access_token');

      //  this.logout(true);
    }
    var titlmessage = this.userName;

    Swal.fire({
      title: "<h2><label>Account locked</label><br/><img width='42' src=" + this.userImagePath + " class='rounded-circle mb-2' + style='background: linear-gradient(#FFFFFF, #E0E0E0) !important;border: #E0E0E0 1px solid !important'><br/>" + titlmessage + "</h2>",
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off'
      },
      cancelButtonText: '<i class="fa fa-power-off " ></i>',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: '<i class="fa fa-unlock-alt"></i>',
      allowOutsideClick: false,
      customClass: {
        container: "swal-blur",
        popup: "swal-popup"
      },
      showLoaderOnConfirm: true,
      preConfirm: (passwordval) => {
        const login = this.tokenEmail;
        if (!login || !passwordval) {
          Swal.showValidationMessage(`Please enter password`);
        } else {
          return { login: login, password: passwordval }
        }
      }
      //   allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.CheckPassword(result.value.login, result.value.password)
          .then((logggedin) => {
            if (logggedin === false) {
              Swal.fire({
                title: this.translationService.translate(this.errorDescription.trim()),
                allowOutsideClick: false,
                customClass: {
                  container: "swal-blur",
                  popup: "swal-popup"
                },
              }).then((result) => {
                this.swalWarning();
              });
            } else {
              this.swalOpen = "close";
            }
          });
      } else {
        this.logout();
      }
    })

    // Swal.fire({
    //   title: "<h2><label>Account locked</label><br/><img width='42' src=" + this.userImagePath + " class='rounded-circle mb-2' + style='background: linear-gradient(#FFFFFF, #E0E0E0) !important;border: #E0E0E0 1px solid !important'><br/>" + titlmessage + "</h2>",
    //   text: "You have been Idle!",
    //   input: 'text',
    //   inputAttributes: {
    //     autocapitalize: 'off'
    //   },
    //  //html: `<input type="password" autocomplete="false"  id="password" class="swal2-input" placeholder="Password" autofocus>`,
    //   cancelButtonText: '<i class="fa fa-power-off " ></i>',
    //   showCancelButton: true,
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: '<i class="fa fa-unlock-alt"></i>',
    //   allowOutsideClick: false,
    //   customClass: {
    //     container: "swal-blur",
    //     popup: "swal-popup"
    //   },
    //   preConfirm: () => {
    //     const login = this.tokenEmail;
    //     const password = Swal.getPopup().querySelector('#password')["value"]
    //     if (!login || !password) {
    //       Swal.showValidationMessage(`Please enter password`);
    //     } else {
    //       return { login: login, password: password }
    //     }
    //   }
    // }).then((result) => {
    //   if (result.value) {
    //     this.CheckPassword(result.value.login, result.value.password)
    //       .then((logggedin) => {
    //         if (logggedin === false) {
    //           Swal.fire({
    //             title: this.translationService.translate(this.errorDescription.trim()),
    //             allowOutsideClick: false,
    //             customClass: {
    //               container: "swal-blur",
    //               popup: "swal-popup"
    //             },
    //           }).then((result) => {
    //             this.swalWarning();
    //           });
    //         } else {
    //           this.swalOpen = "close";
    //         }
    //       });
    //   } else {
    //     this.logout();
    //   }
    // })

  }


  // Checking if token is set
  isLoggedIn() {
    return localStorage.getItem('access_token') != null;
  }
  getJwtToken() {
    return localStorage.getItem('access_token');
  }
  logout(noredirectlogin?) {
    for (let event of this.eventList) {
      document.body.removeEventListener(event, () => null);
    }
    this.logoutCall();
    localStorage.clear();

    if (noredirectlogin) {

    } else {
      this.router.navigate(['/login']);
    }
  }

  refreshToken() {
    if (localStorage.getItem("RefreshToken") != null && localStorage.getItem("RefreshToken") != "") {
      var formdata = new FormData();

      formdata.append("grant_type", this.grantTypeRefreshToken);
      //formdata.append("scope", "openid offline_access");
      formdata.append("client_id", this.clienttId);
      formdata.append("client_secret", this.clienttSecret);
      formdata.append("refresh_token", this.getRefreshToken());
      var response = this.http
        .post<any>(this.basePath, formdata)
        .pipe(tap((response: LoginResponse) => {
          if (response.status != "fail") {
            this.storeJwtToken(response.Accesstoken);
          }
          else {
            this.userIdle.stopWatching();
            this.sharedService.kikedout = true;
            this.logout();

          }
        }, catchError((error: any) => {
          return observableThrowError(new RejectedResponse(error));
        })));
      response.subscribe();
      return response;
    }
    return null;
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }
  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }
  // Get data from server for Dashboard
  getData(data): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(this.basePath, data, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }
  Savesresponse(responce) {

    return this.http.post("'http://192.168.0.103:16004/UserAccount/api/token/SignInGoogle", responce);
  }
  public socialSignIn() {
  }
  private CheckPassword(username, password): Promise<boolean> {
    return new Promise((resolve, reject) => {
      var formdata = new FormData();
      var rt = localStorage.getItem("RefreshToken");

      formdata.append("Username", username);
      formdata.append("password", password);
      formdata.append("grant_type", this.loginenvironmentData.GrantTypePassWord);
      formdata.append("client_id", this.loginenvironmentData.ClientId);
      formdata.append("client_secret", this.loginenvironmentData.ClientSecret);
      formdata.append("refresh_token", rt);

      this.loginForm(formdata).subscribe(loginresponse => {
        if (loginresponse.status.toLowerCase() === 'success'.toLowerCase()) {
          localStorage.setItem('name', loginresponse.Name);
          localStorage.setItem('access_token', loginresponse.Accesstoken);
          localStorage.setItem('RefreshToken', loginresponse.Refreshtoken);
          localStorage.setItem('hasresetpassword', loginresponse.HasResetPassword ? "true" : "false");
          this.StartUserActivity();
          localStorage.setItem("isLoggedIn", "true");
          resolve(true);
        }
        else {
          this.errorDescription = loginresponse.ErrorDescription;
          resolve(false);
          //Swal.showValidationMessage(errorDescription);
        }

      }, error => {

        console.error(error);
      });

    });
  }

  logoutCall() {
    var t = localStorage.getItem("access_token");
    var rt = localStorage.getItem("RefreshToken");
    this.logoutModel.AccessToken = t;
    this.logoutModel.RefreshToken = rt;
    this.logoutModel.Event = this.eventName;
    this.logoutModel.IpAddress = "103.94.194.176";
    // this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
    //   this.logoutModel.IpAddress = res.ip;

    var headers_object = new HttpHeaders().set("Authorization", "Bearer " + t);
    const httpOptions = {
      headers: headers_object
    };
    localStorage.clear();
    return this.http
      .post<any>(this.logoutBasepath, this.logoutModel, httpOptions)
      .pipe(
        tap((res) => {
          console.log("-> Result", res);
        }),
        catchError((error: any) => {
          return observableThrowError(new RejectedResponse(error));
        })
      )
      .toPromise();
    // });
  }

  public sessionLogout(username: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.eventName = "Session Logout";
      return this.http
        .post<any>(this.logoutSessionpath + "?username=" + username + "&clientId=" + this.clienttId, null)
        .pipe(
          tap((res) => {
            console.log("-> Result", res);
            resolve();
          }),
          catchError((error: any) => {
            return observableThrowError(new RejectedResponse(error));
          })
        )
        .toPromise();
    })
  }
}