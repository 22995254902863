import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
} from "@angular/core";
import { ThemeOptions } from "../../../theme-options";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { control } from "pck-general-components";
import { AccordionComponent } from "src/app/accordion/accordion.component";
import { JwtHelperService } from '@auth0/angular-jwt';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  @ViewChild("viewContainerRef", { static: true, read: ViewContainerRef })
  VCR: ViewContainerRef;

  public extraParameter: any;
  public selectmenu: string;
  public permissionMenu: Array<control> = [];
  public mainMenu: Array<control> = [
    {
      icon: "vsm-icon pe-7s-portfolio",
      id: "1",
      parentid: "0",
      title: "Menu.Dashboard",
      url: "/dashboard",
      aclmodule: ["Dashboard"],
    },
    {
      icon: "vsm-icon pe-7s-monitor",
      id: "2",
      parentid: "0",
      title: "Menu.License",
      url: "",
      aclmodule: ["Dashboard"],
    }
  ];

  @select("config") public config$: Observable<any>;
  private newInnerWidth: number;
  private innerWidth: number;
  activeId = "dashboardsMenu";
  componentsReferences = [];

  constructor(
    private CFR: ComponentFactoryResolver,
    public globals: ThemeOptions,
    public jwtHelperService: JwtHelperService,
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    if (localStorage.getItem('toggleSidebar') == null) {
      this.globals.toggleSidebar = false;
    } else {
      this.globals.toggleSidebar = (localStorage.getItem('toggleSidebar') == "true");
    }

    var tokenValue = this.jwtHelperService.decodeToken(localStorage.getItem('access_token'));
    if (tokenValue) {
      
    } else {
      this.router.navigate(["/login"]);
    }
    this.globals.aclModule = tokenValue.Permission;
    var permission = JSON.parse(this.globals.aclModule);
    this.mainMenu.forEach(menu => {
      permission.forEach(element => {
        if (menu.aclmodule.length > 0 && menu.aclmodule.includes(element.Name) && element.permission > 0) {
          if (this.permissionMenu.indexOf(menu) === -1)
            this.permissionMenu.push(menu);
        }
      });
    });
  }

  createComponent(params: string) {
    let _url = this.permissionMenu.find((x) => x.title == params).url;
    if (_url == "") {
      this.selectmenu = params;
      document.getElementById("divselectmenu").style.display = "block";
      document.getElementById("divselectmenu").style.visibility = "Visible";
      var x = document
        .getElementById("divmainMenu")
        .getElementsByClassName("vsm-title");
      for (var i = 0; i < x.length; i++) {
        let htmleEl = x[i] as HTMLElement;
        htmleEl.style.display = "none";
      }
      // Clear previous bind html
      this.VCR.clear();
      //set static parentId
      AccordionComponent.ParentId = this.permissionMenu.find(
        (x) => x.title == params
      ).id;

      let componentFactory = this.CFR.resolveComponentFactory(
        AccordionComponent
      );
      let componentRef: ComponentRef<AccordionComponent> = this.VCR.createComponent(
        componentFactory
      );
      // add reference for newly created component
      this.componentsReferences.push(componentRef);
    }
  }

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    localStorage.setItem('toggleSidebar', this.globals.toggleSidebar.toString());
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }

  menuclick(event, params: string) {
    this.selectmenu = params;
    document.getElementById("divselectmenu").style.display = "block";
    document.getElementById("divselectmenu").style.visibility = "Visible";
    var x = document.getElementsByClassName("vsm-title");
    for (var i = 0; i < x.length; i++) {
      let htmleEl = x[i] as HTMLElement;
      htmleEl.style.display = "none";
    }
    //const factory = cfr.resolveComponentFactory(AccordionComponent);
  }

  backMainMenuClick() {
    this.selectmenu = "";
    document.getElementById("divselectmenu").style.display = "none";
    document.getElementById("divselectmenu").style.visibility = "Hidden";
    var x = document.getElementsByClassName("vsm-title");
    for (var i = 0; i < x.length; i++) {
      let htmleEl = x[i] as HTMLElement;
      htmleEl.style.display = "inline";
    }
  }

  reloadUrl() {
    if (this.router.url.indexOf("dashboard") === -1) {
      this.router.navigate(["dashboard"]);
    } else {
      this._document.defaultView.location.reload();
    }
  }
}
