<div class="app-footer" style="height: 6px !important;">
  <div class="app-footer__inner">
    <p>SoftClinic GenX Copyright By JVS Technology @2020</p>
    <!-- <div class="app-footer-left">
      <app-footer-dots></app-footer-dots>
    </div>
    <div class="app-footer-right">
      <app-footer-menu></app-footer-menu>
    </div> -->
  </div>
</div>