import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../../../theme-options';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginauthService } from 'src/app/Service/Loginauth.service';
@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {
  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }
  fullName: string = "";
  email: string = "";
  EmployeeId: string = "";
  firstChar: string = "";
  userImagePath: string = "";

  constructor(public globals: ThemeOptions, public router: Router,
    public jwtHelperService: JwtHelperService,
    public authService: LoginauthService) {
  }

  ngOnInit() {
    if (this.authService.isLoggedIn() && !this.jwtHelperService.isTokenExpired(localStorage.getItem('access_token'))) {
      var tokenValue = this.jwtHelperService.decodeToken(localStorage.getItem('access_token'));
      this.fullName = tokenValue.idp;
      this.firstChar = this.fullName.substr(0, 1);

      this.userImagePath = "./assets/images/avatars/" + this.firstChar.toLowerCase() + ".png";

      this.email = localStorage.getItem("name");
      this.EmployeeId = localStorage.getItem("EmployeeId");

    }
  }
  logout() {
    var formdata = new FormData();
    this.authService.logoutCall();
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  

}
