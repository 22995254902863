import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgRedux, DevToolsExtension, NgReduxModule } from "@angular-redux/store";
//import { CommonModule } from "@angular/common";
import { CommonModule, DatePipe } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { ToastrModule } from "ngx-toastr";
import { RoundProgressModule } from "angular-svg-round-progressbar";

// LAYOUT
import { BaseLayoutComponent } from "./Layout/base-layout/base-layout.component";
import { AppsLayoutComponent } from "./Layout/apps-layout/apps-layout.component";
import { PagesLayoutComponent } from "./Layout/pages-layout/pages-layout.component";
import { PageTitleComponent } from "./Layout/Components/page-title/page-title.component";

// HEADER
import { HeaderComponent } from "./Layout/Components/header/header.component";
import { DotsComponent } from "./Layout/Components/header/elements/dots/dots.component";
import { SearchBoxComponent } from "./Layout/Components/header/elements/search-box/search-box.component";
import { MegamenuComponent } from "./Layout/Components/header/elements/mega-menu/mega-menu.component";
import { MegapopoverComponent } from "./Layout/Components/header/elements/mega-menu/elements/megapopover/megapopover.component";
import { UserBoxComponent } from "./Layout/Components/header/elements/user-box/user-box.component";
import { DrawerComponent } from "./Layout/Components/header/elements/drawer/drawer.component";

// SIDEBAR
import { SidebarComponent } from "./Layout/Components/sidebar/sidebar.component";
import { LogoComponent } from "./Layout/Components/sidebar/elements/logo/logo.component";
import { AccordionComponent } from "./accordion/accordion.component";

// FOOTER
import { FooterComponent } from "./Layout/Components/footer/footer.component";
import { FooterDotsComponent } from "./Layout/Components/footer/elements/footer-dots/footer-dots.component";
import { FooterMenuComponent } from "./Layout/Components/footer/elements/footer-menu/footer-menu.component";

// Theme
import { rootReducer, ArchitectUIState } from "./ThemeOptions/store";
import { ConfigActions } from "./ThemeOptions/store/config.actions";
import { ThemeOptions } from "./theme-options";
import { OptionsDrawerComponent } from "./ThemeOptions/options-drawer/options-drawer.component";

//For Jwt

import { TokenInterceptor } from "./Interceptor/AuthInterceptor";

import { UserIdleModule } from "angular-user-idle";

export function tokenGetter() {
  return localStorage.getItem("access_token");
}
// export function socialConfigs()
// {
//   const config = new AuthServiceConfig(
//     [

//       {
//         id: GoogleLoginProvider.PROVIDER_ID,
//         provider: new GoogleLoginProvider('626337487880-92o93fpid1ldtulf3j8746uocht7m7h5.apps.googleusercontent.com')
//       }
//     ]
//   );
//   return config;
// }
//   return config;
var jwtConf = {
  config: {
    tokenGetter: tokenGetter,
  },
};
import { SCLanguageSelectionComponent } from "./Translation/Components/language-selection.component";

import {
  L10nConfig,
  TranslationModule,
  StorageStrategy,
  ProviderType,
  LogLevel,
} from "angular-l10n";

import { environment } from "../environments/environment";
import { LoginComponent } from "./components/login/login.component";

import { JwtModule, JwtHelperService } from "@auth0/angular-jwt";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClientXsrfModule,
} from "@angular/common/http";
// import {
//   AuthServiceConfig,
//   AuthService,
// } from "angular-6-social-login";
import { LoginauthService } from "./Service/Loginauth.service";
import { MyLoaderComponent } from "./components/my-loader/my-loader.component";
import { LoaderService } from "./Service/loader.service";
import { LoaderInterceptor } from "./Interceptor/loader-interceptor.service";
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { BusinessAccountRegistrationComponent } from './components/business-account-registration/business-account-registration.component';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// import { AngularFontAwesomeModule } from "angular-font-awesome";
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
//import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatExpansionModule, MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TrendModule } from 'ngx-trend';

import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { NgSelectModule } from '@ng-select/ng-select';

import { TextMaskModule } from 'angular2-text-mask';
import { UserMastersService } from 'user-service-app';
import { EnvironmentService, PckApiModule } from 'pck-api';
import { FormValidationService, PckGeneralComponentsModule, ResourceTranslationService } from 'pck-general-components';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SharedService } from './Service/shared.service';
// Projects

const l10nConfig: L10nConfig = {
  logger: {
    level: LogLevel.Warn,
  },
  locale: {
    defaultLocale: { languageCode: environment.defaultLanguage },
    language: environment.defaultLanguage,
    storage: StorageStrategy.Cookie,
  },
  translation: {
    providers: [
      { type: ProviderType.Static, prefix: "./assets/locales/locale-" },
    ],
    caching: true,
    composedKeySeparator: ".",
    missingValue: " ",
  },
};
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    // LAYOUT
    AppComponent,
    BaseLayoutComponent,
    AppsLayoutComponent,
    PagesLayoutComponent,
    OptionsDrawerComponent,
    PageTitleComponent,

    // HEADER
    HeaderComponent,
    DotsComponent,
    SearchBoxComponent,
    MegamenuComponent,
    MegapopoverComponent,
    UserBoxComponent,
    DrawerComponent,

    // SIDEBAR
    SidebarComponent,
    LogoComponent,

    // FOOTER
    FooterComponent,
    FooterDotsComponent,
    FooterMenuComponent,

    // Components
    SCLanguageSelectionComponent,
    AccordionComponent,
    LoginComponent,
    MyLoaderComponent,
    ChangePasswordComponent,
    BusinessAccountRegistrationComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    RoundProgressModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: "XSRF-TOKEN",
      headerName: "X-XSRF-TOKEN",
    }),
    // Translation
    TranslationModule.forRoot(l10nConfig),
    JwtModule.forRoot(jwtConf),
    UserIdleModule.forRoot({ idle: 300, timeout: 25, ping: 300 }),
    NgxDocViewerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    LoadingBarRouterModule,
    PerfectScrollbarModule,
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // AngularFontAwesomeModule,
    NgbModule,
    NgReduxModule,
    LoadingBarRouterModule,
    PerfectScrollbarModule,
    TrendModule,
    NgSelectModule,
    TextMaskModule,
    PckApiModule.forRoot(),
    PckGeneralComponentsModule.forRoot()

  ],
  providers: [
    LoaderService,
    UserMastersService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: "Window", useValue: window },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    LoginauthService,
    //  AuthService,
    // {
    //   provide: AuthServiceConfig,
    //   // useFactory: socialConfigs
    // },
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    EnvironmentService,
    ConfigActions,
    ThemeOptions,
    DatePipe,
    ResourceTranslationService,
    FormValidationService,
    SharedService
  ],
  bootstrap: [AppComponent],
  entryComponents: [AccordionComponent],
})
export class AppModule {
  constructor(
    private ngRedux: NgRedux<ArchitectUIState>,
    devTool: DevToolsExtension
  ) {
    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : (f) => f]
    );
  }
}
