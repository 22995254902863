<link
  href="https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
  rel="stylesheet">
<link
  href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
  rel="stylesheet">
<div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
  <div class="logo-src"></div>
  <div class="app-header__content">
    <div class="app-header-left">
      <!-- <app-mega-menu></app-mega-menu> -->
      <div class="form-row cpoe-1">
        <div class="col-md-12">
          <div class="cpoe">
            <!-- <i class="fa fa-angle-left" aria-hidden="true"></i><img src="./assets/images/avatars/icon-1.jpg" alt=""> -->
            <!-- <h3 id="MainHeaderText" class="text-themecolor mb-0">CPOE</h3> -->
          </div>
        </div>
      </div>
    </div>
    <div class="app-header-right">
      <app-mega-menu></app-mega-menu>
      <app-dots></app-dots>
      <app-user-box></app-user-box>
    </div>
  </div>
  <div class="app-header__mobile-menu">
    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
      [ngClass]="{ 'is-active': globals.toggleSidebarMobile }" (click)="toggleSidebarMobile()">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </div>
  <div class="app-header__menu">
    <span>
      <button class="btn-icon btn-icon-only btn btn-primary" [ngClass]="{ 'is-active': globals.toggleHeaderMobile }"
        (click)="toggleHeaderMobile()">
        <div class="btn-icon-wrapper">
          <!-- <fa name="ellipsis-v"></fa> -->
        </div>
      </button>
    </span>
  </div>
</div>
<style>
  .cpoe {
    display: inline-flex;
    width: 100%;
    float: left;
  }

  .cpoe img {
    width: 22px;
    height: 24px;
  }

  .cpoe i {
    padding-right: 12px;
    font-size: 20px;
  }

  .cpoe h3 {
    font-size: 16px;
    color: #313131;
    line-height: 19px;
    font-family: 'Roboto Condensed', sans-serif;
    padding-left: 10px;
    padding-top: 3px;
  }
</style>