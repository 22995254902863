<div [hidden]="!loading"
    style="display: flex; justify-content: center; position: fixed; top: 0; left: 0; right: 0; bottom: 0; align-items: center; z-index: 999; background-color: rgba(0,0,0,0.3);">
    <div class="loader">
        <div class="ball-pulse">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
