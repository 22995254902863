export class BusinessAccount {
  name: string;
  primaryContactPerson: string;
  displayName: string;
  code: string;
  email: string;
  recaptchaReactive: string;
  ContactDetails: string;
  password: string;
  confirmPassword: string;
}
export class ContactDetail {
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
}
