import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { BaseLayoutComponent } from "./Layout/base-layout/base-layout.component";
import { LoginComponent } from './components/login/login.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { BusinessAccountRegistrationComponent } from './components/business-account-registration/business-account-registration.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: "",
    component: PagesLayoutComponent,
    children: [
      {
        path: "",
        component: LoginComponent,
      },
      {
        path: "login",
        component: LoginComponent,
      }, {
        path: "user/changepassword",
        component: ChangePasswordComponent,
      },
      {
        path: "Registration",
        component: BusinessAccountRegistrationComponent,
      },
    ]
  },
  {
    path: "",
    component: BaseLayoutComponent,
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path: 'license',
        loadChildren: () => import('./modules/lazy-license-service-app.module').then(m => m.LazyLicenseServiceAppModule)
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
