<div class="header-dots">
    <div class="btn-group" ngbDropdown placement="bottom">
        <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
            <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div class="icon-wrapper-bg bg-info"></div>
                    <div class="language-icon flag flag-icon-background flag-icon-{{currentLanguage.icon}}"></div>
                </div>
            </span>
        </button>
        <div ngbDropdownMenu>
            <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner pt-4 pb-4 bg-info">
                    <div class="menu-header-image opacity-05 dd-header-bg-4"></div>
                    <div class="menu-header-content text-center text-white">
                        <h6 class="menu-header-subtitle mt-0">{{translation.translate("Common.ChooseLanguage")}}</h6>
                    </div>
                </div>
            </div>
            <!-- <h6 class="dropdown-header">Popular Languages</h6> -->
            <sc-language-selection (CallforIcon)="getCurrentLanguage($event)"></sc-language-selection>
        </div>
    </div>
</div>