<div class="app-sidebar-content app-sidebar-child" style=" background:#ffffff !important;height:100%">
  <div class="app-sidebar-scroll">
    <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [scrollIndicators]="true"
      style="max-height: 600px;">
      <!-- <div class="v-sidebar-menu vsm-default" style="padding: 0 0.8rem; margin-top: 10px;"> -->
      <div class="v-sidebar-menu vsm-default" style="padding: 0 !important; margin-top: 10px;">
        <div *ngFor="let menu of permissionMenu">
          <div class="vsm-list">
            <ngb-accordion [closeOthers]="true" *ngIf="menu.url == ''">
              <ngb-panel id="dashboardsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link"
                    style="padding: 0;color: black;cursor: pointer;height: 1.9rem;" routerLinkActive="active-item">
                    <label class="vsm-title" style="bottom: 5px;padding: 0 0.75rem 0.75rem !important;" translate>{{
                      menu.title }}</label>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div *ngFor="let submenu of menu.Subcontrol">
                        <div class="vsm-item">
                          <a style="padding: 0;color: black;" routerLink="{{ submenu.url }}"
                            routerLinkActive="active-item" class="vsm-link"><label class="vsm-title"
                              style="padding-left: 1.5rem !important;" translate>{{
                              submenu.title
                              }}</label></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [closeOthers]="true" *ngIf="menu.url != ''">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link"
                    style="padding: 0;color: black;cursor: pointer;height: 1.9rem;" routerLink="{{ menu.url }}"
                    routerLinkActive="active-item">
                    <label class="vsm-title" style="bottom: 5px;padding: 0 0.75rem 0.75rem !important;" translate>{{
                      menu.title }}</label>
                  </button>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</div>