<!-- <div class="app-header-left">
  <app-search-box (click)="hideMegamenu()"></app-search-box> -->
<ul [ngStyle]="{'opacity': value ? '0' : '1'}" class="header-megamenu nav">
  <li class="nav-item">
    <button [ngbPopover]="headerMegaPopover" [autoClose]="'auto-close'" placement="bottom-left"
      popoverClass="rm-max-width" type="button" class="btn btn-link dropdown-toggle">
      <span>
        <i class="nav-link-icon pe-7s-gift"></i>
        Quick Links
      </span>
    </button>
  </li>
  <!-- <li class="nav-item">
    <div class="btn-group" ngbDropdown>
      <button class="btn btn-link" ngbDropdownToggle>
        <span>
          <div class="badge badge-pill badge-danger ml-0 mr-2">4</div>
            Settings
        </span>
      </button>
      <div ngbDropdownMenu>
        <div class="dropdown-menu-header">
          <div class="dropdown-menu-header-inner bg-focus">
            <div class="menu-header-image opacity-2 dd-header-bg-5"></div>
            <div class="menu-header-content">
              <h5 class="menu-header-title">Overview</h5>
              <h6 class="menu-header-subtitle">Dropdown menus for everyone</h6>
            </div>
          </div>
        </div>
        <div class="scroll-area-xs">
          <perfect-scrollbar [autoPropagation]="true">
            <h6  class="dropdown-header">Key Figures</h6>
            <button type="button" class="dropdown-item">Service Calendar</button>
            <button type="button" class="dropdown-item">Knowledge Base</button>
            <button type="button" class="dropdown-item">Accounts</button>
            <div  class="dropdown-divider"></div>
            <button type="button" class="dropdown-item">Products</button>
            <button type="button" class="dropdown-item">Rollup Queries</button>
          </perfect-scrollbar>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item-divider nav-item"></li>
          <li class="nav-item-btn nav-item">
            <button class="btn-wide btn-shadow btn btn-danger btn-sm">Cancel</button>
          </li>
        </ul>
      </div>
    </div>
  </li>
  <li class="nav-item">
    <div  class="dropdown-menu-rounded btn-group" ngbDropdown>
      <button type="button" class="btn btn-link" ngbDropdownToggle>
        <span>
          <i class="nav-link-icon pe-7s-settings"></i>
          Projects
        </span>
      </button>
      <div class="dropdown-menu-lg" ngbDropdownMenu>
        <div class="dropdown-menu-header">
          <div class="dropdown-menu-header-inner bg-success">
            <div class="menu-header-image opacity-1 dd-header-bg-3"></div>
            <div class="menu-header-content text-left">
              <h5 class="menu-header-title">Overview</h5>
              <h6 class="menu-header-subtitle">Unlimited options</h6>
              <div class="menu-header-btn-pane">
                <button class="mr-2 btn btn-dark btn-sm">Settings</button>
                <button class="btn-icon btn-icon-only btn btn-warning btn-sm">
                  <i class="pe-7s-config btn-icon-wrapper"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="dropdown-item"><i class="dropdown-icon lnr-file-empty"></i>Graphic Design</button>
        <button type="button" class="dropdown-item"><i class="dropdown-icon lnr-file-empty"></i>App Development</button>
        <button type="button" class="dropdown-item"><i class="dropdown-icon lnr-file-empty"></i>Icon Design</button>
        <div  class="dropdown-divider"></div>
        <button type="button" class="dropdown-item"><i class="dropdown-icon lnr-file-empty"></i>Miscellaneous</button>
        <button type="button" class="dropdown-item"><i class="dropdown-icon lnr-file-empty"></i>Frontend Dev</button>
      </div>
    </div>
  </li> -->
</ul>
<!-- </div> -->


<ng-template #headerMegaPopover>
  <div class="dropdown-mega-menu" style="width: 15rem !important;">
    <div class="col-sm-12">
      <ul class="nav flex-column">
        <li class="nav-item-header nav-item">Pages</li>
        <li class="nav-item"><a routerLink="/patient/patientregistration/create/quickcreate" class="nav-link"><i
              class="nav-link-icon lnr-picture"></i><span>Patient Registration</span></a></li>
        <li class="nav-item"><a routerLink="/cpoe/appointment-outpatient/create" class="nav-link"><i
              class="nav-link-icon lnr-book"></i><span>Appointment</span></a></li>
        <li class="nav-item"><a routerLink="/cpoe/patietorder" class="nav-link"><i
              class="nav-link-icon pe-7s-monitor"></i><span>CPOE</span>
          </a></li>
        <li class="nav-item"><a routerLink="/accounts/billing-main" class="nav-link"><i
              class="nav-link-icon lnr-cart"></i><span>Billing</span></a></li>
      </ul>
    </div>
    <!--  <div class="grid-menu grid-menu-3col">
      <div class="no-gutters row">        
        <div class="col-sm-6" style="border-right-width: 0px !important;">
          <ul class="nav flex-column">
            <li class="nav-item-header nav-item">Reports</li>
            <li class="nav-item"><a disabled="disabled" routerLink="/common/comingsoon" class="nav-link disabled">Coming
                Soon</a></li>
            <li class="nav-item"><a [routerLink]="" class="nav-link">Quick Start
                <div class="ml-auto badge badge-success">New</div>
              </a></li>
            <li class="nav-item"><a [routerLink]="" class="nav-link">Users &amp; Groups</a></li>
            <li class="nav-item"><a [routerLink]="" class="nav-link">Proprieties</a></li>
          </ul>
        </div> 
      </div>
    </div>-->
  </div>
</ng-template>