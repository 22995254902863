import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from, observable } from 'rxjs';
import { LoaderService } from '../Service/loader.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { switchMap, finalize } from 'rxjs/operators';
import { FormValidationService, ResourceTranslationService } from 'pck-general-components';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService, public translationService: ResourceTranslationService,
    private formValidationService: FormValidationService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  showconfirm(): Promise<SweetAlertResult> {
    var title = this.translationService.translate("Swal.updateTitle");
    var text = this.translationService.translate("Swal.updateText");
    var confirmButtonText = this.translationService.translate("Swal.updateconfirmButtonText");
    var cancelButtonText = this.translationService.translate("Swal.cancelButtonText");
    return Swal.fire({
      title: title,
      text: text,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: cancelButtonText,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText
    });
  }

  loadIntercept(req, next): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true);
    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            this.removeRequest(req);
            if (err.status === 403) {
              var resultText = this.translationService.translate("Common.MasterRecordUpdate");
              Swal.fire(
                resultText,
                '',
                'error'
              );
            }
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        this.loaderService.isLoading.next(false);
        subscription.unsubscribe();
      };
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.requests.push(req);
    //return this.loadIntercept(req, next).pipe(finalize(() => this.loaderService.isLoading.next(false)));
    console.log("No of requests--->" + this.requests.length);
    if (navigator.onLine === false) {
      var resultText = this.translationService.translate("Common.NoInternetConnection");
      Swal.fire(
        resultText,
        '',
        'error'
      );
      return;
    }
    if (req.method == "PUT") {
      return from(this.showconfirm()).pipe(
        switchMap((token) => {
          console.log(token);
          if (token.value) {
            return this.loadIntercept(req, next);
          }
        }),
        finalize(() => this.loaderService.isLoading.next(false))
      );
    }
    else {
      return this.loadIntercept(req, next).pipe(finalize(() => this.loaderService.isLoading.next(false)));
    }
  }
}
