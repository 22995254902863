import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { LanguageModel, ResourceTranslationService } from 'pck-general-components';

@Component({
  selector: "sc-language-selection",
  templateUrl: "language-selection.component.html",
})
export class SCLanguageSelectionComponent implements OnInit {
  public languages: LanguageModel[] = [];
  public currentLanguage: LanguageModel = new LanguageModel();
  @Output() CallforIcon = new EventEmitter<any>();
  constructor(private translation: ResourceTranslationService) { }

  ngOnInit() {
    this.languages = this.translation.languages;
    this.setCurrentLanguage();
  }

  setLanguage(language: LanguageModel) {
    this.translation.setLanguage(language);
    this.setCurrentLanguage();
  }

  private setCurrentLanguage() {
    this.currentLanguage = this.languages.find(
      (item) => item.code === this.translation.currentLanguage
    );
    this.CallforIcon.emit(this.currentLanguage);
  }
}
