<div class="h-100">
  <div class="row no-gutters">
    <div class="d-none d-lg-block col-lg-4">
      <div class="slider-light h-100">
        <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig2">
          <div ngxSlickItem *ngFor="let data of staticData">
            <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-plum-plate">
              <div class="slide-img-bg"></div>
              <div class="slider-content text-light">
                <h3> {{ data.title }}</h3>
                <p>
                  {{ data.content }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div ngxSlickItem>
                        <div
                            class="position-relative h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                            <div class="slide-img-bg"></div>
                            <div class="slider-content text-light">
                                <h3>Scalable, Modular, Consistent</h3>
                                <p>
                                    Easily exclude the components you don't require. Lightweight,
                                    consistent Bootstrap based styles across all elements and
                                    components
                                </p>
                            </div>
                        </div>
                    </div>
                    <div ngxSlickItem>
                        <div
                            class="position-relative h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                            <div class="slide-img-bg opacity-6"></div>
                            <div class="slider-content text-light">
                                <h3>Complex, but lightweight</h3>
                                <p>
                                    We've included a lot of components that cover almost all use
                                    cases for any type of application.
                                </p>
                            </div>
                        </div>
                    </div> -->
        </ngx-slick-carousel>
      </div>
    </div>
    <div class="d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
      <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
        <div class="app-logo"></div>
        <h4 class="mb-0">
        </h4>
        <h6 class="mt-3">
          <a routerLink="/login" routerLinkActive="active" class="text-primary">
            Back to Login</a>
        </h6>
        <div class="row">
          <div class="col-md-12">
            <div class="main-card mb-3 card">
              <div class="card-body">
                <form [formGroup]="formGroup" class="needs-validation" novalidate>
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="position-relative form-group">
                        <label for="name" class="asterisk_input" translate>BusinessAccount.Name</label>
                        <input autofocus formControlName="Name" type="text" class="form-control" id="name" [ngClass]="{
                                                            'is-invalid': submitted && f.Name.errors
                                                          }" [(ngModel)]="item.name"
                          placeholder="{{translationService.translate('BusinessAccount.NameP')}}" />
                        <!-- <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                          <div *ngIf="f.Name.errors.required">
                            <label translate> Common.Required</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('Name').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("Name").errors?.serverError)}}</label>
                        </div> -->

                        <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                          <div *ngIf="f.Name.errors.required">
                            <label translate> Common.NameReq</label>
                          </div>
                          <div *ngIf="f.Name.errors.pattern">
                            <label translate> Common.OnlyAlphaNumeric</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('Name').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("Name").errors?.serverError)}}</label>
                        </div>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="position-relative form-group">
                        <label class="asterisk_input" for="primaryContactPerson"
                          translate>BusinessAccount.ContactPerson</label>
                        <input formControlName="primaryContactPerson" type="text" class="form-control"
                          id="primaryContactPerson"
                          [ngClass]="{'is-invalid': submitted && f.primaryContactPerson.errors}"
                          [(ngModel)]="item.primaryContactPerson"
                          placeholder="{{translationService.translate('BusinessAccount.ContactPersonP')}}" />
                        <!-- <div *ngIf="submitted && f.primaryContactPerson.errors" class="invalid-feedback">
                          <div *ngIf="f.primaryContactPerson.errors.required">
                            <label translate> Common.Required</label>
                          </div>
                        </div> -->
                        <!-- <div *ngIf="formGroup.get('primaryContactPerson').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("primaryContactPerson").errors?.serverError)}}</label>
                        </div> -->
                        <div *ngIf="submitted && f.primaryContactPerson.errors" class="invalid-feedback">
                          <div *ngIf="f.primaryContactPerson.errors.required">
                            <label translate> Common.NameReq</label>
                          </div>
                          <div *ngIf="f.primaryContactPerson.errors.pattern">
                            <label translate> Common.OnlyAlphaNumeric</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('primaryContactPerson').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("primaryContactPerson").errors?.serverError)}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="position-relative form-group">
                        <label class="asterisk_input" for="displayName" translate>Common.DisplayName</label>
                        <input formControlName="displayName" type="text" class="form-control" id="displayName"
                          [ngClass]="{'is-invalid': submitted && f.displayName.errors}" [(ngModel)]="item.displayName"
                          placeholder="{{translationService.translate('BusinessAccount.DisplayNameP')}}" />
                        <!-- <div *ngIf="submitted && f.displayName.errors" class="invalid-feedback">
                          <div *ngIf="f.displayName.errors.required">
                            <label translate> Common.Required</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('displayName').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("displayName").errors?.serverError)}}</label>
                        </div> -->
                        <div *ngIf="submitted && f.displayName.errors" class="invalid-feedback">
                          <div *ngIf="f.displayName.errors.required">
                            <label translate> Common.NameReq</label>
                          </div>
                          <div *ngIf="f.displayName.errors.pattern">
                            <label translate> Common.OnlyAlphaNumeric</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('displayName').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("displayName").errors?.serverError)}}</label>
                        </div>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="position-relative form-group">
                        <label class="asterisk_input" for="code" translate>BusinessAccount.Code</label>
                        <input formControlName="code" type="text" class="form-control" id="code"
                          [ngClass]="{'is-invalid': submitted && f.code.errors}" [(ngModel)]="item.code"
                          placeholder="{{translationService.translate('BusinessAccount.CodeP')}}" />
                        <!-- <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                          <div *ngIf="f.code.errors.required">
                            <label translate> Common.Required</label>
                          </div>
                        </div> -->
                        <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                          <div *ngIf="f.code.errors.required">
                            <label translate> Common.NameReq</label>
                          </div>
                          <div *ngIf="f.code.errors.pattern">
                            <label translate> Common.OnlyAlphaNumeric</label>
                          </div>
                        </div>

                        <div *ngIf="formGroup.get('code').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("code").errors?.serverError)}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="position-relative form-group">
                        <label class="asterisk_input" for="email" translate>BusinessAccount.Email</label>
                        <input formControlName="Email" type="text" class="form-control" id="email"
                          [ngClass]="{'is-invalid': submitted && f.Email.errors}" [(ngModel)]="item.email"
                          placeholder="{{translationService.translate('BusinessAccount.EmailP')}}" />
                        <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                          <div *ngIf="f.Email.errors.required">
                            <label translate> Common.Required</label>
                          </div>
                          <div *ngIf="f.Email.errors.email">
                            <label translate>Common.EmailFormat</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('Email').errors?.serverError" class="invalid-feedback">
                          <label translate>{{formGroup.get("Email").errors?.serverError}}</label>
                        </div>
                        <!-- <div *ngIf="formGroup.get('email').errors?.serverError" class="invalid-feedback">
                        <label>{{translationService.translate(formGroup.get("email").errors?.serverError)}}</label>
                      </div> -->
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="position-relative form-group">
                        <label class="asterisk_input" for="Phone" translate>BusinessAccount.Phone</label>
                        <input formControlName="phone" type="text" class="form-control" id="phone"
                          [ngClass]="{'is-invalid': submitted && f.phone.errors}" [(ngModel)]="contactDetail.phone"
                          placeholder="{{translationService.translate('BusinessAccount.PhoneP')}}" />
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                          <div *ngIf="f.phone.errors.required">
                            <label translate> Common.Required</label>
                          </div>
                          <div *ngIf="f.phone.errors.pattern">
                            <label translate>Common.MobileNoFormat</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('phone').errors?.serverError" class="invalid-feedback">
                          <label>{{formGroup.get("phone").errors?.serverError}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="position-relative form-group">
                        <label class="asterisk_input" for="Password" translate>BusinessAccount.Password</label>
                        <input formControlName="Password" type="password" class="form-control" id="password"
                          [ngClass]="{'is-invalid': submitted && f.Password.errors}" [(ngModel)]="item.password"
                          placeholder="{{translationService.translate('BusinessAccount.PasswordP')}}" />
                        <div *ngIf="submitted && f.Password.errors" class="invalid-feedback">
                          <div *ngIf="f.Password.errors.required">
                            <label translate> Common.Required</label>
                          </div>

                        </div>
                        <div *ngIf="formGroup.get('Password').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("Password").errors?.serverError)}}</label>
                        </div>
                        <!-- <div *ngIf="formGroup.get('email').errors?.serverError" class="invalid-feedback">
                                                        <label>{{translationService.translate(formGroup.get("email").errors?.serverError)}}</label>
                                                      </div> -->
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="position-relative form-group">
                        <label for="ConfirmPasswordP" translate>BusinessAccount.ConfirmPassword</label>
                        <input formControlName="ConfirmPassword" type="password" class="form-control"
                          id="ConfirmPasswordP" [ngClass]="{'is-invalid': submitted && f.ConfirmPassword.errors}"
                          [(ngModel)]="item.confirmPassword"
                          placeholder="{{translationService.translate('BusinessAccount.ConfirmPasswordP')}}" />
                        <div *ngIf="submitted && f.ConfirmPassword.errors" class="invalid-feedback">
                          <div *ngIf="f.ConfirmPassword.errors.required">
                            <label translate> Common.Required</label>
                          </div>
                          <div *ngIf="f.ConfirmPassword.errors?.mustMatch">
                            <label translate> User.passwordMatch</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('ConfirmPassword').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("ConfirmPassword").errors?.serverError)}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="position-relative form-group">
                        <label class="asterisk_input" for="Address" translate>Common.Address</label>
                        <input formControlName="address" type="text" class="form-control" id="address"
                          [ngClass]="{'is-invalid': submitted && f.address.errors}" [(ngModel)]="contactDetail.address"
                          placeholder="{{translationService.translate('BusinessAccount.AddressP')}}" />
                        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                          <div *ngIf="f.address.errors.required">
                            <label translate> Common.Required</label>
                          </div>
                          <div *ngIf="f.address.errors.pattern">
                            <label translate> Common.OnlyAlphaNumeric</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('address').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("address").errors?.serverError)}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="position-relative form-group">
                        <label class="asterisk_input" for="city" translate>Common.City</label>
                        <input formControlName="city" type="text" class="form-control" id="city"
                          [ngClass]="{'is-invalid': submitted && f.city.errors}" [(ngModel)]="contactDetail.city"
                          placeholder="{{translationService.translate('BusinessAccount.Cityp')}}" />
                        <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                          <div *ngIf="f.city.errors.required">
                            <label translate> Common.Required</label>
                          </div>
                          <div *ngIf="f.city.errors.pattern">
                            <label translate> Common.OnlyAlphaNumeric</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('city').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("city").errors?.serverError)}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="position-relative form-group">
                        <label class="asterisk_input" for="state" translate>Common.State</label>
                        <input formControlName="state" type="text" class="form-control" id="state"
                          [ngClass]="{'is-invalid': submitted && f.state.errors}" [(ngModel)]="contactDetail.state"
                          placeholder="{{translationService.translate('BusinessAccount.StateP')}}" />
                        <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                          <div *ngIf="f.state.errors.required">
                            <label translate> Common.Required</label>
                          </div>
                          <div *ngIf="f.state.errors.pattern">
                            <label translate> Common.OnlyAlphaNumeric</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('state').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("state").errors?.serverError)}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="position-relative form-group">
                        <label class="asterisk_input" for="zip" translate>BusinessAccount.Zip</label>
                        <input formControlName="zip" type="text" class="form-control" id="zip"
                          [ngClass]="{'is-invalid': submitted && f.zip.errors}" [(ngModel)]="contactDetail.zip"
                          placeholder="{{translationService.translate('BusinessAccount.ZipP')}}" />
                        <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                          <div *ngIf="f.zip.errors.required">
                            <label translate> Common.Required</label>
                          </div>
                          <div *ngIf="f.zip.errors.pattern">
                            <label translate> Common.OnlyAlphaNumeric</label>
                          </div>
                        </div>
                        <div *ngIf="formGroup.get('zip').errors?.serverError" class="invalid-feedback">
                          <label>{{translationService.translate(formGroup.get("zip").errors?.serverError)}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-6">
                      <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive"
                        [(ngModel)]="item.recaptchaReactive" siteKey="6LcsQEsaAAAAABlyz6xSXxjjBvwRuiYq8JH2okJ5">
                      </re-captcha>
                      <div *ngIf="submitted && f.recaptchaReactive.errors" class="invalid-feedback">
                        <div *ngIf="f.recaptchaReactive.errors.required">
                          <label translate>Common.Required</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="divider">
                  </div>
                  <div class="form-row">
                    <div class="col-md-12 align-right">
                      <button class="btn btn-primary mr-2" type="submit" (click)="save()">
                        <label translate> Common.Save</label>
                      </button>
                      <button class="btn btn-secondary" type="button" (click)="ResetForm()">
                        <label translate> Common.Cancel</label>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>