import { Component, OnInit } from '@angular/core';
import { LanguageModel, ResourceTranslationService } from 'pck-general-components';

@Component({
  selector: 'app-dots',
  templateUrl: './dots.component.html',
})
export class DotsComponent implements OnInit {
  public currentLanguage: LanguageModel = new LanguageModel();
  constructor(public translation: ResourceTranslationService) { }

  ngOnInit() {
  }

  getCurrentLanguage(event: any) {
    this.currentLanguage = event;
  }

}
