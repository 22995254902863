<div class="d-flex">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" src="{{userImagePath}}" alt="" class="rounded-circle"
                    style="background: linear-gradient(#FFFFFF, #E0E0E0) !important;border: #E0E0E0 1px solid !important;">
                </div>
              </span>
            </button>
            <div class="dropdown-menu-lg" ngbDropdownMenu>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <img width="42" src="{{userImagePath}}" alt="" class="rounded-circle"
                            style="background:  linear-gradient(#FFFFFF, #E0E0E0) !important;">
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading" [textContent]="fullName">

                          </div>
                          <div class="widget-subheading opacity-8">{{email}}</div>
                        </div>
                        <div class="widget-content-right mr-2">
                          <button class="btn-pill btn-shadow btn-shine btn" style="background: #fff;color: red;"
                            (click)="logout()">Logout</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scroll-area-xs">
                <perfect-scrollbar [autoPropagation]="true">
                  <ul class="nav flex-column">
                    <li class="nav-item-header nav-item">Activity</li>
                    <li class="nav-item"><a autofocus routerLink="/user/changepassword" class="nav-link">Change
                        Password</a></li>
                    <!-- <li class="nav-item"><a [routerLink]="" class="nav-link">Profile Picture</a></li> -->
                    <li class="nav-item-header nav-item" style="text-transform:none !important">Version : <label
                        translate>About.version</label>
                    </li>
                    <!--<li class="nav-item-header nav-item">My Account</li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link">Settings
                        <div class="ml-auto badge badge-success">New</div>
                      </a></li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link">Messages
                        <div class="ml-auto badge badge-warning">512</div>
                      </a></li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link">Logs</a></li> -->
                  </ul>
                </perfect-scrollbar>
              </div>
              <!--<ul class="nav flex-column">
                <li class="nav-item-divider mb-0 nav-item"></li>
              </ul>
              <div class="grid-menu grid-menu-2col overflow-hidden">
                <div class="no-gutters row">
                  <div class="col-sm-6">
                    <button
                      class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-warning"><i
                        class="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"></i>Message Inbox</button>
                  </div>
                  <div class="col-sm-6">
                    <button
                      class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-danger"><i
                        class="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"></i><b>Support
                        Tickets</b></button>
                  </div>
                </div>
              </div>
              <ul class="nav flex-column">
                <li class="nav-item-divider nav-item"></li>
                <li class="nav-item-btn text-center nav-item">
                  <button class="btn-wide btn btn-primary btn-sm">Open Messages</button>
                </li>
              </ul> -->
            </div>
          </div>
        </div>
        <!-- <div class="widget-content-left ml-3 header-user-info">
          <div class="widget-heading" [textContent]="fullName"> </div>
          <div class="widget-subheading">Nurse</div>
        </div> -->
        <!-- <div class="widget-content-right header-user-info ml-3">
          <button type="button" class="btn btn-shadow p-1 btn-info btn-sm" placement="bottom"
            ngbTooltip="Tooltip Example!">
            <fa name="calendar" class="mr-1 ml-1"></fa>
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <!-- <div class="header-btn-lg">
    <button type="button" class="hamburger hamburger--elastic" [ngClass]="{'is-active' : globals.toggleDrawer}"
      (click)="toggleDrawer()" ngbTooltip="Appointment Details!">
      <fa name="calendar" class="mr-1 ml-1"></fa>
    </button>
  </div> -->
</div>