<div class="header-menu-item dropdown-menu-rounded btn-group" ngbDropdown style="display: block;">
    <!-- <button type="button" class="btn btn-link" ngbDropdownToggle>
        <span>
            <i class="link-icon fa fa-language"></i>
            {{currentLanguage.englishName}}
        </span>
    </button> -->
    <ng-container *ngFor="let language of languages">
        <button type="button"
            [ngClass]="(language.englishName==currentLanguage.englishName)?'dropdown-item active':'dropdown-item'"
            (click)="setLanguage(language)">
            <div class="language-icon flag flag-icon-background flag-icon-{{language.icon}}"></div>
            <span>{{language.englishName}}</span>
        </button>
        <br>
    </ng-container>
</div>