<div [class]="'app-sidebar ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()" (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <a (click)="reloadUrl()">
      <div class="logo-src"></div>
    </a>
    <div class="header__pane ml-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{ 'is-active': globals.toggleSidebar }" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [scrollIndicators]="true"
        style="max-width: 600px;">
        <div id="divselectmenu" class="vsm-list vsm-link divdrop" style="height: 100%;">
          <i class="vsm-icon pe-7s-back" (click)="backMainMenuClick()"
            style="color: white;margin-left: -30px;height: 2.0rem;margin-right: 6px;padding-top: 2px;font-size: 1.5rem;"></i>
          <label class="vsm-title" translate (click)="backMainMenuClick()" style="color:white;padding-left: 10px;">{{
            selectmenu }}</label>
          <ng-template #viewContainerRef></ng-template>
        </div>
        <div class="v-sidebar-menu vsm-default" style="margin-top: 2.0rem;">
          <div id="divmainMenu" class="vsm-list">
            <ng-container *ngFor="let menuitem of permissionMenu">
              <!-- <button id="divselectmenu" class="vsm-list vsm-link divdrop" style="height: 100%;"
                (click)="backMainMenuClick()">
                <i class="vsm-icon pe-7s-back" (click)="backMainMenuClick()" style="color: white;"></i>
                <label class="vsm-title" translate>{{ menuitem.title }}</label>
              </button>
              <ng-template #viewContainerRef></ng-template> -->
              <!-- <div id="divselectmenu" class="vsm-list divdrop" style="height: 100%;">
                <i class="vsm-icon pe-7s-back" (click)="backMainMenuClick()" style="color: white;"></i>
                <label class="vsm-title" translate (click)="backMainMenuClick()"
                  style="color:white;padding-left: 10px;">{{ selectmenu }}</label>
                <ng-template #viewContainerRef></ng-template>
              </div> -->
              <button *ngIf="menuitem.url != ''" class="vsm-link" (click)="createComponent(menuitem.title)"
                routerLink="{{ menuitem.url }}">
                <i class="{{ menuitem.icon }}"></i>
                <label class="vsm-title" translate>{{ menuitem.title }}</label>
              </button>
              <button *ngIf="menuitem.url == ''" class="vsm-link" (click)="createComponent(menuitem.title)">
                <i class="{{ menuitem.icon }}"></i>
                <label class="vsm-title" translate>{{ menuitem.title }}</label>
              </button>
            </ng-container>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>