import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ResourceTranslationService } from 'pck-general-components';
import { EnvironmentService, PublicRestService } from "pck-api";

@Injectable()
export class SharedService extends PublicRestService {
    constructor(
        @Inject(HttpClient) http: HttpClient,
        @Inject(EnvironmentService) public environmentData: EnvironmentService,
        public translationService: ResourceTranslationService
    ) {
        super(http);
    }

    kikedout: boolean = false;
}