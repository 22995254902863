import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { EnvironmentService } from 'pck-api';
import { ResourceTranslationService } from 'pck-general-components';
import Swal from 'sweetalert2';
import { SharedService } from 'src/app/Service/shared.service';
import { LoginauthService } from 'src/app/Service/Loginauth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  ErrorDescription: string;
  staticData = [
    { "title": "Easy, intuitive, and right for your practice", "content": "It is easy to use, easy to learn and adapts to the way you work without compromising efficiency. It covers both the administrative and financial processes of your practice so you can focus more on delivering quality care to your patients while increasing your bottom line." },
    { "title": "Streamline your workflow, boost productivity, and increase ROI", "content": "Running a successful practice involves managing various aspects—from the nitty-gritty of daily operations to the intricacies of patient care and it can be difficult to handle things simultaneously. With Meditab’s Practice Management solution integrated in our EHR system, we give you tools to support your daily operations and meet the growing demands of your clinic." },
    { "title": "Digital Hospital", "content": "Comprehensive and powerful one platform to run your entire hospital." },
    { "title": "Connecting care, improving health", "content": "Empowering clinicians and people with the healthcare IT of tomorrow, today." },
  ];
  loginForm: FormGroup;
  clienttId: string;
  clienttSecret: string;
  grantTypePassWord: string;
  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };
  model: any = {};
  formGroup = new FormGroup({
    username: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", Validators.required)
  });
  submitted = false;

  constructor(private router: Router, private formBuilder: FormBuilder,
    private authService: LoginauthService,
    environmentData: EnvironmentService,
    public translationService: ResourceTranslationService,
    public sharedService: SharedService,
  ) {
    this.clienttId = environmentData.ClientId;
    this.clienttSecret = environmentData.ClientSecret;
    this.grantTypePassWord = environmentData.GrantTypePassWord;
    this.ErrorDescription = "";
  }

  ngOnInit() {
    if (this.sharedService.kikedout) {
      this.ErrorDescription = this.translationService.translate("Common.SessionOut")
    }
    // this.loginForm = this.formBuilder.group({
    //   email: ['', Validators.required],
    //   password: ['', Validators.required]
    // });
  }

  get loginform() {
    return this.formGroup.controls;
  }

  login() {

    this.submitted = true;
    if (this.formGroup.invalid) {
      return;
    }
    var formdata = new FormData();
    formdata.append("Username", this.model.username);
    formdata.append("password", this.model.password);
    formdata.append("grant_type", this.grantTypePassWord);
    //formdata.append("scope", "openid offline_access all");
    formdata.append("client_id", this.clienttId);
    formdata.append("client_secret", this.clienttSecret);

    this.authService.loginForm(formdata).subscribe(response => {

      if (response.status.toLowerCase() === 'success'.toLowerCase()) {
        localStorage.setItem('name', response.Name);
        localStorage.setItem('access_token', response.Accesstoken);
        localStorage.setItem('RefreshToken', response.Refreshtoken);
        localStorage.setItem('hasresetpassword', response.HasResetPassword ? "true" : "false");

        this.authService.StartUserActivity();
        localStorage.setItem("isLoggedIn", "true");

        if (response.HasResetPassword) {
          this.router.navigate(["/user/changepassword"]);
        }
        else
          this.router.navigate(["/dashboard"]);
      }
      else {
        //this.ErrorDescription = response.ErrorDescription;
        if (response.ErrorDescription != undefined && response.ErrorDescription.toLocaleLowerCase() == "common.loggederror") {
          Swal.fire({
            title: this.translationService.translate("Common.Warning"),
            text: this.translationService.translate(response.ErrorDescription),
            icon: "question",
            cancelButtonText: this.translationService.translate("Common.Cancel"),
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: this.translationService.translate("Common.Ok"),
          }).then((result) => {
            if (result.value) {
              ///this.onCancelSelect.emit();
              this.authService.sessionLogout(this.model.username).then(() => {
                this.login();
              });
            }
          });
        }
        else { this.ErrorDescription = response.ErrorDescription; }

      }

    }, error => {

      console.error(error);
    });

  }
  loginWithGoogle(Provider: string) {

    //this.authService.socialSignIn(Provider);
    //localStorage.setItem("isLoggedIn", "true");
    //this.router.navigate(["/dashboards/product"]);
  }

}
