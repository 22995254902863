import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RejectedResponse } from 'pck-api';
import { FormValidationService, ResourceTranslationService } from 'pck-general-components';
import Swal from 'sweetalert2';
import { ChangePasswordModel, UserMastersService } from 'user-service-app';

@Component({
  selector: 'user-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  item: ChangePasswordModel = new ChangePasswordModel();
  // formGroup: FormGroup;
  formGroup = new FormGroup({
    CurrentPassword: new FormControl("", Validators.required),
    NewPassword: new FormControl("", Validators.required),
    ConfirmPassword: new FormControl("", Validators.required),
  }, {
    validators: this.Matchpassword.bind(this)
  });
  submitted = false;
  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };
  staticData = [
    { "title": "Easy, intuitive, and right for your practice", "content": "It is easy to use, easy to learn and adapts to the way you work without compromising efficiency. It covers both the administrative and financial processes of your practice so you can focus more on delivering quality care to your patients while increasing your bottom line." },
    { "title": "Streamline your workflow, boost productivity, and increase ROI", "content": "Running a successful practice involves managing various aspects—from the nitty-gritty of daily operations to the intricacies of patient care and it can be difficult to handle things simultaneously. With Meditab’s Practice Management solution integrated in our EHR system, we give you tools to support your daily operations and meet the growing demands of your clinic." },
    { "title": "Digital Hospital", "content": "Comprehensive and powerful one platform to run your entire hospital." },
    { "title": "Connecting care, improving health", "content": "Empowering clinicians and people with the healthcare IT of tomorrow, today." },
  ];

  constructor(
    private userMastersService: UserMastersService,
    private router: Router,
    private route: ActivatedRoute,
    private formValidationService: FormValidationService,
    public translationService: ResourceTranslationService
  ) { }
  get f() {
    return this.formGroup.controls;
  }
  ngOnInit() {
    // this.Bindformgroup();
  }
  Matchpassword(formGroup: FormGroup) {

    const { value: password } = formGroup.get('NewPassword');
    const { value: confirmPassword } = formGroup.get('ConfirmPassword');
    const matchingControl = formGroup.controls["ConfirmPassword"];

    if (password === confirmPassword) {
      matchingControl.setErrors(null);
    }
    else { matchingControl.setErrors({ mustMatch: true }); }
  }
  onBackClick() {
    this.backToList();
  }

  private backToList() {
    this.router.navigate(["dashboard"]);
  }
  save() {
    this.submitted = true;

    if (!this.item.confirmPassword) {
      this.formGroup.get('ConfirmPassword').setErrors({ 'required': true })
    }
    if (this.formGroup.invalid) {
      return;
    }
    this.userMastersService.ChangePassowrd(this.item).then(
      () => {
        Swal.fire({
          title: 'success',
          text: 'Your Password has been changed',
          icon: 'success',
          confirmButtonColor: '#1f7d34',
          confirmButtonText: "Ok",
        }).then((result) => {
          localStorage.clear();
          this.router.navigate(['/login']);
        });
      },
      (rejected: RejectedResponse) => {
        this.formValidationService.BindServerErrors(this.formGroup, rejected);
      }
    );
  }

}
