<!-- <general-page-title heading="User.ChangePassword" [createButton]="false">
</general-page-title> -->

<div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-12">
  <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-12">
    <div class="app-logo"></div>
    <div>
      <div class="divider"></div>
      <div class="col-md-12">
        <form [formGroup]="formGroup" class="needs-validation" novalidate>
          <div class="form-row">
            <div class="col-md-4">
              <div class="position-relative form-group">
                <label for="currentPassword" class="asterisk_input" translate>User.CurrentPassword</label>
                <input autofocus formControlName="CurrentPassword" type="password" class="form-control"
                  id="currentPassword" [ngClass]="{
                    'is-invalid': submitted && f.CurrentPassword.errors
                  }" [(ngModel)]="item.currentPassword"
                  placeholder="{{translationService.translate('User.CurrentPasswordPH')}}" required />
                <div *ngIf="submitted && f.CurrentPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.CurrentPassword.errors.required">
                    <label translate> User.CurrentPasswordReq</label>
                  </div>
                </div>
                <div *ngIf="formGroup.get('CurrentPassword').errors?.serverError" class="invalid-feedback">
                  <label>{{translationService.translate(formGroup.get("CurrentPassword").errors?.serverError)}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-4">
              <div class="position-relative form-group">
                <label for="newPassword" class="asterisk_input" translate>User.NewPassword</label>
                <input formControlName="NewPassword" type="password" class="form-control" id="newPassword" [ngClass]="{
                    'is-invalid': submitted && f.NewPassword.errors
                  }" [(ngModel)]="item.newPassword"
                  placeholder="{{translationService.translate('User.NewPasswordPH')}}" required />
                <div *ngIf="submitted && f.NewPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.NewPassword.errors.required">
                    <label translate> User.NewPasswordReq</label>
                  </div>
                </div>
                <div *ngIf="formGroup.get('NewPassword').errors?.serverError" class="invalid-feedback">
                  <label>{{formGroup.get("NewPassword").errors?.serverError}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-4">
              <div class="position-relative form-group">
                <label for="confirmPassword" class="asterisk_input" translate>User.ConfirmPassword</label>
                <input formControlName="ConfirmPassword" type="password" class="form-control" id="confirmPassword"
                  [ngClass]="{
                      'is-invalid': submitted && f.ConfirmPassword.errors
                    }" [(ngModel)]="item.confirmPassword"
                  placeholder="{{translationService.translate('User.ConfirmPassword')}}" required />
                <div *ngIf="submitted && f.ConfirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.ConfirmPassword.errors?.mustMatch">
                    <label translate> User.passwordMatch</label>
                  </div>
                  <div *ngIf="f.ConfirmPassword.errors.required">
                    <label translate> User.ConfirmPasswordReq</label>
                  </div>
                </div>
                <div *ngIf="formGroup.get('ConfirmPassword').errors?.serverError" class="invalid-feedback">
                  <label>{{translationService.translate(formGroup.get("ConfirmPassword").errors?.serverError)}}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="divider"></div>
          <div class="form-row">
            <div class="col-md-12 align-right">
              <button class="btn btn-primary mr-2" type="submit" (click)="save()">
                <label translate>User.ChangePassword</label>
              </button>
              <gc-general-cancel-button [formGroup]="formGroup" [label]="'Cancel'" (onCancelSelect)="onBackClick()">
              </gc-general-cancel-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>